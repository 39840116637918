import "./App.css";
import React, { FC, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";


import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import Mint from "./Mint";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");


function App() {
  const treasury = new anchor.web3.PublicKey(
    process.env.REACT_APP_TREASURY_ADDRESS
  );
  
  const config = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_CONFIG
  );
  
  const candyMachineId = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_ID
  );
  
  const network = process.env.REACT_APP_SOLANA_NETWORK;
  console.log("CD", candyMachineId);
  const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
  const connection = new anchor.web3.Connection(rpcHost);
  
  const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE, 10);
  
  const txTimeout = 30000; // milliseconds (confirm this works for your project)
  
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  
    const wallets = useMemo(
      () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getLedgerWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
      ],
      [network]
    );
  
    const { publicKey, sendTransaction } = useWallet();
    console.log(publicKey);
  return (
    <div className="App">
     <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Mint
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout}
            />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
