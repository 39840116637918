
import React, { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./services/candy-machine";
import back from './Back.png'
import leftimg from './Pandlite 1 Web.png'
import rightimg from './Pandlite 2 Web.png'
import centerimg from './Kotodama Logo 25.gif'
import subcenterimg from './Mint Now Button.png'
import logo from './Solana Full.png'
import pic from './pic.png'
import img1 from './1k.gif'
import img2 from './2k.gif'
import img3 from './3k.gif'
import img4 from './4k.gif'
import img5 from './5k.gif' 
import img6 from './6k.gif'
import img7 from './7k.gif'
import img8 from './8k.gif'
import img9 from './9k.gif'
import img10 from './10k.gif'
import { useAlert } from 'react-alert'
import { publicKey } from "@project-serum/anchor/dist/cjs/utils";


export default function Mint(props) {
  const alert = useAlert()
  const [balance, setBalance] = useState();
  const [isActive, setIsActive] = useState(true); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const onMint = async () => {

    try {
      setIsMinting(true);
      if(isMinting){
        alert.show("Minting has started!");
      }
      
      console.log(candyMachine.program);
      if (wallet && candyMachine.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
      
          alert.show('Congratulations! Mint succeeded!')
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
     
          alert.show('Mint failed! Please try again!')
        }
      }
    } catch (error) {
      // TODO: blech:
      // alert.show('Minting failed! Try to Connect Wallet Again')
      
      let message = error.msg || "Minting failed! Please connect wallet again!";
      alert.show(message);
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
          alert.show('SOLD OUT!')
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
          alert.show('Insufficient funds to mint. Please fund your wallet.')
          console.log(message)
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
          alert.show("Minting period hasn't started yet.")
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
    console.log(alertState)
  };

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet,
        props.candyMachineId,
        props.connection
      );
      console.log("IA", itemsAvailable);
      console.log("IR", itemsRemaining);
      console.log("IReddemed", itemsRedeemed);
      console.log("Go LIVE Date", goLiveDate);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);
      setCandyMachine(candyMachine);
      console.log(isActive);

      setIsSoldOut(itemsRemaining === 0);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);
  return (
    <>
    <div className="back">
        <img src={back} alt="" />
        <div className="text"
        >Back</div>
      </div>
      
      {/* Main screen */}
      <div className="main">
        
        {/* left Section */}
        <div className="left">
          <img src={leftimg} alt="" />
        </div>
        
        {/* Center Section */}
        <div className="center">
          <img className='logo' src={centerimg} alt="" />
          <div className="wallet__btn">
        <WalletMultiButton />
      </div>
          <div style={{ display:'flex' }} id="demo" className="btn__container">
            
         <div style={{cursor:'pointer'}} disabled={isSoldOut || isMinting || !isActive}>
         <img className="mint__bkg" onClick={onMint} src={pic} alt=""/>
         </div>
         
            <div className="mintText">
  
              <span className="imgText">0.65 <img  className="solana" src={logo} alt="" />&nbsp;Per Pull</span>

            </div>
          </div>
          {/* <div style={{position:'relative',top:0,left:0}}>
          <img className='mintButton' src={subcenterimg} alt="" />
          
          <div className="mint__now">
                  <button
                    className="btn"
                    disabled={isSoldOut || isMinting || !isActive}
                    onClick={onMint}
                  >
              
                    {isSoldOut
                      ? "SOLD OUT"
                      : isActive
                      ? isMinting
                        ? "Minting"
                        : "MINT NOW"
                      : "Count Down"}
                  
                  </button>
                </div>
          </div> */}
        </div>

        {/* Right Section */}
        <div className="right">
          <img src={rightimg} alt="" />
        </div>
      </div>
      <div className="imglist">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
        <img src={img5} alt="" />
        <img src={img6} alt="" />
        <img src={img7} alt="" />
        <img src={img8} alt="" />
        <img src={img9} alt="" />
        <img src={img10} alt="" />
      </div>





 
    </>
  );
}